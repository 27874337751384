import { extendTheme } from "@chakra-ui/react";
import { ThemeConfig } from "@chakra-ui/theme";
import { Button } from "./button/button";
import { Table } from "./table/table";
import { Input } from "./input/input";

// Import fonts
import "@fontsource/poppins/300.css";
import "@fontsource/poppins/400.css";
import "@fontsource/poppins/600.css";
import "@fontsource/fredoka-one/400.css";

const config: ThemeConfig = {
  initialColorMode: "light",
  useSystemColorMode: false,
};

export const theme = extendTheme({
  config,
  styles: {
    global: (props: any) => ({
      "html, body": {
        margin: 0,
        padding: 0,
        color: "gray.600",
        backgroundColor: "cherryUi.800",
        overflowX: "hidden",
      },
      "h1, h2, h3, h4, h5, h6": {
        color: props.colorMode === "light" ? "gray.600" : "white",
      },
    }),
  },
  colors: {
    black: "#000",
    white: "#fff",
    // Color scheme for cherryhub buttons
    aCherryGrey: {
      1100: "#46556880",
    },
    cherryGrey: {
      150: "#EEEEEE",
      1100: "#465568",
    },
    cherryButton: {
      50: "#ED8C83",
      100: "#e04d4c",
      500: "#ee3443", // bg
      600: "#bb2834", // hoverBg
      700: "#6e171e", // activeBg
    },
    cherryButtonDark: {
      50: "#ED8C83",
      100: "#e04d4c",
      500: "#9e1029", // bg
      600: "#6b0a1b", // hoverBg
      700: "#510815", // activeBg
    },
    cherryButtonDarkHighlighted: {
      50: "#ED8C83",
      100: "#e04d4c",
      500: "#c41836", // bg
      600: "#9e1029", // hoverBg
      700: "#6b0a1b", // activeBg
    },
    cherryUi: {
      600: "#c41836",
      700: "#9e1029",
      800: "#7a121e",
    },
  },
  fonts: {
    heading: "Poppins, Helvetica, sans-serif",
    body: "Poppins, Helvetica, sans-serif",
  },
  components: {
    Button,
    Table,
    Input,
    Checkbox: {
      defaultProps: {
        colorScheme: "cherryButton",
      },
    },
    Radio: {
      defaultProps: {
        colorScheme: "cherryButton",
      },
    },
    Header: {
      baseStyle: {
        fontWeight: "600",
      },
      variants: {
        content: {
          color: "blue",
        },
      },
    },
    Modal: {
      baseStyle: {
        header: {
          backgroundColor: "cherryUi.600",
          color: "#fff",
          borderTopRightRadius: "var(--chakra-radii-md)",
          borderTopLeftRadius: "var(--chakra-radii-md)",
        },
        body: {
          paddingTop: "var(--chakra-space-6)",
        },
        closeButton: {
          color: "#fff",
        },
      },
    },
    Text: {
      variants: {
        "view-label": {
          fontSize: "sm",
          textTransform: "uppercase",
          color: "aCherryGrey.1100",
        },
        "view-value": {
          fontSize: "sm",
          fontWeight: "semibold",
          color: "cherryGrey.1100",
        },
        "small-label": {
          fontSize: "xs",
          color: "whiteAlpha.700",
          textTransform: "uppercase",
        },
      },
    },
    Heading: {
      variants: {
        "view-heading": {
          fontSize: "xl",
          color: "cherryUi.600",
          paddingBottom: 4,
        },
      },
    },
    Tabs: {
      variants: {
        edit: {
          tab: {
            _selected: {
              color: "cherryGrey.900",
              bgColor: "white",
              borderTopLeftRadius: "md",
              borderTopRightRadius: "md",
              boxShadow: "md",
            },
            // bgColor: "cherryGrey.160",
            color: "cherryGrey.1100",
            px: 12,
            textTransform: "uppercase",
            fontSize: "sm",
          },
          tablist: {
            px: 6,
            borderBottom: "none",
            bgColor: "cherryGrey.150",
            pt: 2,
          },
          tabpanels: {
            boxShadow: "md",
            borderRadius: "lg",
            px: 4,
            pt: 8,
            pb: 10,
            bgColor: "white",
            position: "relative",
            zIndex: 99,
          },
        },
      },
    },
  },
});
