import React, { useEffect, useState } from "react";
import { Box, Text, VStack } from "@chakra-ui/layout";
import { InfoOutlineIcon, SmallCloseIcon } from "@chakra-ui/icons";
import { HStack, IconButton } from "@chakra-ui/react";
import { LOCAL_STORAGE_PORTAL_MIGRATION_KEY } from "./Constants";
import { useNavigate } from "react-router-dom";

export const PortalMigrationBanner = () => {
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(() => {
    const localStorageValue = localStorage.getItem(
      LOCAL_STORAGE_PORTAL_MIGRATION_KEY
    );
    return localStorageValue === null || localStorageValue === "true";
  });

  useEffect(() => {
    setIsVisible(
      localStorage.getItem(LOCAL_STORAGE_PORTAL_MIGRATION_KEY) === "true"
    );
  }, [localStorage.getItem(LOCAL_STORAGE_PORTAL_MIGRATION_KEY)]);

  const hideBannerInLocalStorage = () => {
    localStorage.setItem(LOCAL_STORAGE_PORTAL_MIGRATION_KEY, "false");
  };

  const handleClose = (e: React.MouseEvent) => {
    e.stopPropagation();
    hideBannerInLocalStorage();
    setIsVisible(false);
  };

  const handleBannerClick = () => {
    hideBannerInLocalStorage();
    navigate("/portal-migration");
  };

  if (!isVisible) return null;

  return (
    <Box
      sx={{
        backgroundColor: "#61cb80",
        borderRadius: "4px",
        color: "black",
        cursor: "pointer",
        left: "50%",
        maxWidth: "800px",
        paddingRight: "5px",
        position: "absolute",
        py: 1,
        top: "10px",
        transform: "translateX(-50%)",
        width: "100%",
        zIndex: 1000,
      }}
      onClick={handleBannerClick}
    >
      <HStack justify="space-between">
        <HStack>
          <InfoOutlineIcon w="40px" h="25px" />
          <VStack width="100%" spacing="4" alignItems="start">
            <Text style={{ fontWeight: "bold" }}>
              Please sign into the new Cherry Hub Portal today
            </Text>
            <Text style={{ fontSize: 14, margin: 0 }}>
              This site will no longer be available to you after Monday 24th Feb
              2025. Click here for more information.
            </Text>
          </VStack>
        </HStack>
        <IconButton
          aria-label="Close banner"
          icon={<SmallCloseIcon />}
          _hover={{ bg: "#369150" }}
          onClick={handleClose}
          size="sm"
          variant="ghost"
        />
      </HStack>
    </Box>
  );
};
