import React from "react";
import { Text, VStack } from "@chakra-ui/layout";
import { Card } from "../../components/Card/Card";
import { PageHeading } from "../../components/PageHeading/PageHeading";
import { PageContent } from "../../components/PageContent/PageContent";
import { Link } from "@chakra-ui/react";
import { getPortalUrl } from "./PortalUrl";

const linkStyles = { color: "#18a0fb" };

export const PortalMigration = () => {
  const portalLink = getPortalUrl();

  return (
    <>
      <PageHeading>
        <PageHeading.Title>Portal Migration</PageHeading.Title>
      </PageHeading>
      <PageContent>
        <Card width="100%">
          <VStack width="100%" spacing="4" alignItems="start">
            <Text>
              The CherryPay Admin Portal you are currently using has been
              migrated to our new{" "}
              <span style={{ fontWeight: "bold" }}>Cherry Hub Portal.</span>
            </Text>

            <Text>
              Please log in to{" "}
              <Link href={portalLink} style={linkStyles}>
                {portalLink}
              </Link>{" "}
              today.
            </Text>

            <Text>
              You can use your{" "}
              <span style={{ textDecoration: "underline" }}>
                existing Cherry Hub user account
              </span>
              . If you have any issue logging in, please use the "I don’t know
              my password" button to have a password reset email sent to you.
            </Text>

            <Text>
              This portal will no longer be available to you after Monday 24th
              Feb 2025, and will automatically redirect you to the above
              address.
            </Text>

            <Text>
              If you have any issues or other questions, please email{" "}
              <Link href={"mailto:support@cherryhub.com.au"} style={linkStyles}>
                support@cherryhub.com.au
              </Link>{" "}
              for assistance.
            </Text>
          </VStack>
        </Card>
      </PageContent>
    </>
  );
};
