export function getPortalUrl() {
  if (
    window.location.hostname === "cherrypay-admin-portal-staging.web.app" ||
    window.location.hostname === "portal-demo.cherrypay.com.au"
  ) {
    // Some customers use staging for testing/demo. So we need the correct URL for staging too.
    // Portal Demo doesn't have a dedicated PROD URL, so we use the staging URL for it.
    return "https://cherry-hub-portal-staging.firebaseapp.com";
  } else {
    // PROD Cherry Pay has 2 hosts - portal.cherrypay.com.au and cherrypay-admin-portal.web.app
    // Both should point to the below URL.
    // Also, any non-staging env such as dev and localhost will also point to the below URL.
    return "https://portal.cherryhub.com.au";
  }
}
